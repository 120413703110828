<template>
  <b-modal
    :id="id"
    size="md"
    title="Thêm yếu tố"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateRiskElement"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form class="content-form">
        <b-form-group>
          <label for="InputHelp">Tên yếu tố<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
            :custom-messages="customCode"
          >
            <b-form-input
              id="InputHelp"
              v-model="dataInput.name"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="company"
        >
          <label label-for="basicInput">Mức độ nghiêm trọng</label>
          <b-form-input v-model="dataInput.riskLevel" />
        </b-form-group>

        <b-form-group
          label-for="company"
        >
          <label label-for="basicInput">Biện pháp phòng chống</label>
          <b-form-input v-model="dataInput.solution" />
        </b-form-group>

        <b-form-group
          label-for="company"
        >
          <label label-for="basicInput">Người/Bộ phận thực hiện</label>
          <b-form-input v-model="dataInput.owner" />
        </b-form-group>

        <b-form-group
          label-for="company"
        >
          <label label-for="basicInput">Thời gian thực hiện</label>
          <b-form-input v-model="dataInput.time" />
        </b-form-group>

        <b-form-group
          label-for="basicInput"
        >
          <b-form-checkbox
            id="checkbox-2"
            v-model="dataInput.isImprove"
            name="checkbox-2"
          >Có được cải thiện </b-form-checkbox>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BFormCheckbox,
  BFormGroup,
  BModal,
  BFormInput,
  BFormTextarea,
  BForm,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    VSelect,
    DateTimePicker,
    BFormCheckbox,
    BFormTextarea,
    BForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataDetailAccidentWorker: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      customCode: {
        required: 'Tên yếu tố là bắt buộc',
      },
      required,
      dataInput: {
        name: '',
        riskLevel: '',
        solution: '',
        owner: '',
        time: '',
        isImprove: false,
      },
      dataAccidentReason: [],
      dataAccidentType: [],
      dataCombobox: [],
      choiceInjuryType: false,
    }
  },
  methods: {

    // Tạo yếu tố nguy hiểm có hại
    handleCreateRiskElement(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateRiskElement', this.dataInput)
        }
      })
    },
    resetState() {
      this.dataInput = {
        name: '',
        riskLevel: '',
        solution: '',
        owner: '',
        time: '',
        isImprove: false,
      }
    },
  },
}
</script>
