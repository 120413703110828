<template>
  <div id="infoAccidentId">
    <b-form class="content-form">
      <b-row>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Mã đánh giá <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="customMessages"
            >
              <b-form-input
                id="basicInput"
                v-model="dataInfo.code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label label-for="basicInput">Ngày đánh giá <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="date"
              rules="required"
              :custom-messages="customdate"
            >
              <date-time-picker
                v-model="dataInfo.dateEvaluate"
                :disabledInput="true"
                :class="{'is-invalid':errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          v-if="!checkEdit"
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="company"
          >
            <label for="company">Loại đánh giá<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="evaluateType"
              rules="required"
              :custom-messages="evaluateType"
            >
              <v-select
                id="company"
                v-model="dataInfo.evaluateType"
                :reduce="value => value.evaluateType"
                label="evaluateTypeString"
                :options="dataEvaluation || []"
                placeholder="Lựa chọn loại đánh giá"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          md="12"
          xl="12"
          class="mt-1"
        >
          <b-form-group
            label-for="basicInput"
          >
            <b-form-checkbox
              id="checkbox-3"
              v-model="dataInfo.isEvaluate"
              name="checkbox-3"
            >Đánh giá hiệu quả các biện pháp phòng chống các yếu tố nguy hiểm, yếu tố có hại</b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BFormInput,
    DateTimePicker,
    BFormCheckbox,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
  },
  props: {
    dataInfo: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      customMessages: {
        required: 'Mã đánh giá là bắt buộc',
      },
      customdate: {
        required: 'Ngày đánh giá là bắt buộc',
      },
      evaluateType: {
        required: 'Loại đánh giá là bắt buộc',
      },

      dataEvaluation: [
        {
          evaluateType: 'FirstTime',
          evaluateTypeString: 'Đánh giá lần đầu.',
        },
        {
          evaluateType: 'Periodic',
          evaluateTypeString: 'Đánh giá định kỳ',
        },
      ],
      checkEdit: null,
    }
  },
  async created() {
    if (this.dataInfo && this.$route.params.id) {
      this.checkEdit = this.$route.params.id
    }
  },
}
</script>

<style lang="scss">
.checkbox-accident {
  .custom-checkbox {
    margin-top: 1rem;
  }
}
</style>
