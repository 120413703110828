<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      defaultActive="info-evaluation"
      typeCss="active-full"
      @setActive="setActive"
    />

    <div class="page-container">
      <validation-observer ref="tabAccount">
        <info-evaluation
          v-if="isRendered('info-evaluation')"
          id="user-add"
          :dataInfo="dataInfo"
          class="tab-pane"
          :class="{'active':activeTab==='info-evaluation'}"
        />
        <table-dangers
          v-if="isRendered('dangers')"
          id="user-add"
          class="tab-pane"
          :class="{'active':activeTab==='dangers'}"
        />
      </validation-observer>
      <div
        class="mt-2"
      >
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="hadnleCreate"
        >
          Lưu lại
        </b-button>
        <b-button
          variant="outline-primary"
          type="reset"
          @click="backUser"
        >
          Quay lại
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
import {
  BButton,
}
  from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import InfoEvaluation from './components/InfoEvaluation.vue'
import TableDangers from './components/TableDangers.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    MyTabs,
    ValidationObserver,
    ValidationProvider,
    InfoEvaluation,
    BButton,
    TableDangers,
  },
  data() {
    return {
      required,
      activeTab: null,
      listTabs: [
        {
          key: 'info-evaluation',
          title: 'Thông tin',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'dangers',
          title: 'Yếu tố nguy hiểm có hại',
          icon: 'AlertTriangleIcon',
          isDisabled: true,
          isRendered: false,
        },
      ],
      dataInfo: {
        code: '',
        dateEvaluate: '',
        evaluateType: '',
        isEvaluate: false,
      },
      evaluationId: null,
      dataEvaluationId: null,
    }
  },
  watch: {
    dataEvaluationId(val) {
      if (val) {
        this.dataInfo = {
          code: this.dataEvaluationId.code,
          dateEvaluate: this.dataEvaluationId.dateEvaluate,
          evaluateType: this.dataEvaluationId.evaluateType,
          isEvaluate: this.dataEvaluationId.isEvaluate,
        }
      }
    },
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.evaluationId = this.$route.params.id
      this.listTabs[1].isDisabled = false
      this.fetchEvaluationId(this.evaluationId)
    }
    if (this.activeTab === null) {
      this.activeTab = 'info-evaluation'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {
    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },

    // Lấy thông tin khai báo tai nạn lao động theo id
    async fetchEvaluationId() {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_RISK_EVALUATION}${this.evaluationId}`)
      this.dataEvaluationId = data
    },

    // Trở về danh sách
    backUser() {
      this.$router.push({ name: 'risk-evaluation' })
    },

    // Action tạo khai báo tai nạn lao động
    async hadnleCreate(bvModalEvt) {
      if (this.$route.params.id) {
        const data = {
          ...this.dataInfo,
          id: this.$route.params.id,
        }
        bvModalEvt.preventDefault()
        this.$refs.tabAccount.validate().then(async success => {
          if (success) {
            this.$showAllPageLoading()
            await axiosApiInstance.post(ConstantsApi.EDIT_RISK_EVALUATION, data).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Chỉnh sửa thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                this.$router.push({ name: 'risk-evaluation' })
              }
            }).catch(e => {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(e.response.data?.errors[0], {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            })
          }
        })
      } else {
        const data = {
          ...this.dataInfo,
        }
        bvModalEvt.preventDefault()
        this.$refs.tabAccount.validate().then(async success => {
          if (success) {
            this.$showAllPageLoading()
            await axiosApiInstance.post(ConstantsApi.CREATE_RISK_EVALUATION, data).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Tạo thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                this.$router.push({ name: 'risk-evaluation-edit', params: { id: res.data?.data?.id } })
                this.listTabs[1].isDisabled = false
              }
            }).catch(e => {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(e.response.data?.errors[0], {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            })
          }
        })
      }
    },
  },
}
</script>
