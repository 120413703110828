export default {
  LIST_RISK_EVALUATION: 'EvaluateRisk/evaluate-risks',
  DELETE_RISK_EVALUATION: 'EvaluateRisk/delete-evaluate-risk',
  CREATE_RISK_EVALUATION: 'EvaluateRisk/add-evaluate-risk',
  EDIT_RISK_EVALUATION: 'EvaluateRisk/update-evaluate-risk',
  DETAIL_RISK_EVALUATION: 'EvaluateRisk/evaluate-risk/',
  LIST_RISK_ELEMENT: 'EvaluateRisk/list-risk-element',
  DELETE_RISK_ELEMENT: 'EvaluateRisk/delete-risk-element',
  CREATE_RISK_ELEMENT: 'EvaluateRisk/add-risk-element',
}
